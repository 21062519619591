import { IoSchoolOutline } from "react-icons/io5";


export const SidebarData = [
  {
    title: "Students",
    path: "/students",
    icon: <IoSchoolOutline />,
    accessLevel: 0,
  }
];
