import React from "react";
import Expandable from "../Expandable";
import { FaSchool } from "react-icons/fa";
import { FiBook} from "react-icons/fi";
import { AiOutlineEdit, AiOutlineCalendar } from "react-icons/ai";

const ExamMenu = (props) => {
  return (
    <Expandable
      {...props}
      icon={<FaSchool />}
      title="Exams"
      subArray={[
        {
          title: "Book exam",
          icon: <FiBook />,
          path: "/exams/new",
          accessLevel: 0,
          padLeft: "20px",
        },
        {
          title: "Manage exams",
          icon: <AiOutlineEdit />,
          path: "/exams/list",
          accessLevel: 0,
          padLeft: "20px",
        },
        {
          title: "Calendar",
          icon: <AiOutlineCalendar />,
          path: "/exams/calendar",
          accessLevel: 1,
          padLeft: "20px",
        },
      ]}
    />
  );
};

export default ExamMenu;
