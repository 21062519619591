import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppState } from "./context/app/AppState";
import { LayoutState } from "./context/layout/LayoutState";
import { StudentState } from "./context/student/StudentState";
import { AssessmentState } from "./components/pages/profile/nccdProfile/newProfile/newAssessmentProvisions/context/AssessmentState";
import { FileState } from "./components/pages/profile/nccdProfile/newProfile/newFiles/context/FileState";
import SignIn from "./components/pages/signIn/SignIn";
import { MedicalState } from "./components/pages/profile/nccdProfile/newProfile/newMedical/context/MedicalState";
import { NotesState } from "./components/pages/profile/nccdProfile/newProfile/newNotes/context/NotesState";
import { AaraState } from "./components/pages/aaraStudents/context/AaraState";
import { ExamState } from "./components/pages/exams/context/ExamState";
import { EmailState } from "./components/email/context/EmailState";
import { NccdState } from "./components/pages/nccdEntry/context/NccdState";
// import "./styles/App.css";
// import "./styles/Sidebar.css";
// import "./styles/Form.css";
// import "./styles/Date.css";
// import './styles/Assessment.css'
import "react-day-picker/dist/style.css";
import "./styles/App.scss";
import dotenv from "dotenv";
import AppContainer from "./AppContainer";

dotenv.config();

const MainContent = () => {

  console.log(process.env)

  return (
    <Router>
      <AuthenticatedTemplate>

        <AppState>
          <LayoutState>
            <StudentState>
              <AssessmentState>
                <FileState>
                  <MedicalState>
                    <NotesState>
                      <AaraState>
                        <EmailState>
                          <ExamState>
                            <NccdState>
                              <AppContainer />
                            </NccdState>
                          </ExamState>
                        </EmailState>
                      </AaraState>
                    </NotesState>
                  </MedicalState>
                </FileState>
              </AssessmentState>
            </StudentState>
          </LayoutState>
        </AppState>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignIn />
      </UnauthenticatedTemplate>
    </Router>
  );
};

function App() {
  return <MainContent />;
}

export default App;
