import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
// import Home from "./components/pages/home/Home";
// import Profile from "./components/pages/profile/Profile";
// import Error from "./components/global/Error";
// import AaraStudents from "./components/pages/aaraStudents/AaraStudents";
// import Admin from "./components/pages/admin/Admin";

// import ManageExams from "./components/pages/exams/manage/ManageExams";
import LoadComponent from "./components/global/LoadComponent";

// const Home = lazy(() => import("./components/pages/home/Home"));
const Profile = lazy(() => import("./components/pages/profile/Profile"));
const Error = lazy(() => import("./components/global/Error"));
const AaraStudents = lazy(() =>
  import("./components/pages/aaraStudents/AaraStudents")
);
const Admin = lazy(() => import("./components/pages/admin/Admin"));
const ManageExams = lazy(() =>
  import("./components/pages/exams/manage/ManageExams")
);
const History = lazy(() => import("./components/pages/admin/history/History"));
const CaseManager = lazy(() =>
  import("./components/pages/admin/caseManager/CaseManager")
);
const Data = lazy(() => import("./components/pages/data/Data"));
const Nccd = lazy(() => import("./components/pages/nccdEntry/NccdEntry"));
const ViewNccd = lazy(() => import("./components/pages/viewNccd/ViewNccd"));
const NccdProfiles = lazy(() =>
  import("./components/pages/viewNccd/nccdProfiles/NccdProfiles")
);

const AdjustmentSearch = lazy(() =>
  import("./components/pages/adjustmentSearch/AdjustmentSearch")
);

const Reporting = lazy(() =>
  import("./components/pages/nccd/reporting/Reporting")
);
const Analytics = lazy(() => import("./components/pages/analytics/Analytics"));

const ProfileExport = lazy(() =>
  import("./components/pages/admin/profileExport/ProfileExport")
);

const NavRoutes = () => {
  return (
    <Suspense fallback={<LoadComponent />}>
      <Switch>
        {/* <Route exact path="/" component={Home} /> */}
        <Route exact path="/" component={AaraStudents} />
        <Route exact path="/profile/:id" component={Profile} />
        <Route exact path="/students" component={AaraStudents} />
        <Route exact path="/error" component={Error} />
        <Route exact path="/admin" component={Admin} />
        <Route exact path="/history" component={History} />
        <Route exact path="/exams/:id" component={ManageExams} />
        <Route exact path="/cm" component={CaseManager} />
        <Route exact path="/nccd/data" component={Data} />
        <Route exact path="/viewNccd/:personType/:id" component={ViewNccd} />
        <Route path="/nccdEntry/:entryId" component={Nccd} />
        <Route path="/nccdProfiles" component={NccdProfiles} />
        <Route path="/adjustmentSearch" component={AdjustmentSearch} />
        <Route exact path="/nccd/reporting" component={Reporting} />
        <Route exact path="/nccd/analytics" component={Analytics} />
        <Route exact path="/profileExport" component={ProfileExport} />
      </Switch>
    </Suspense>
  );
};

export default NavRoutes;
