import {
  CHANGE_STATE,
  SHIFT_MONTH,
  CHANGE_WINDOW,
  GET_LESSONS,
  SET_MONTH,
  BUILD_NCCD,
  CLEANUP_NCCD,
  SETUP_EMAIL,
} from "./types";
import moment from "moment";

const reducerFunction = (state, action) => {
  switch (action.type) {
    case SETUP_EMAIL:
      return action.payload;
    case CLEANUP_NCCD:
      return {
        students: [],
        showStudentFinder: false,
        recordType: -1,
        specificDates: [],
        specificDatesNoWeekends: [],
        includeWeekends: false,
        dateRange: {
          from: null,
          to: null,
        },
        nccdActions: [],
        selectedActions: [],
        evidence: [],
        evidenceCategories: [],
        blankCategories: {},
        CreatedBy: {},
        CreatedAt: "",
        SchoolDaysOnly: true,
        schoolDays: [],
        selectedTerm: "",
        selectedYear: Number(moment().format('YYYY')),
        schoolYears: [],
        LinkedExam: {
          selectOption: null,
          examId: null,
          isLinked:false
        },
        nccdEmail: {
          isEmail: false,
          actionDescription: "",
        },
      };
    case BUILD_NCCD:
      return action.payload;
    case GET_LESSONS:
      return {
        ...state,
        displayedLessons: action.payload,
        lessonsFetched: true,
      };
    case CHANGE_STATE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      };

    case SHIFT_MONTH:
      return {
        ...state,
        selectedMonth: {
          value:
            action.payload === "forward"
              ? moment(state.selectedMonth.value).add(1, "M").format()
              : moment(state.selectedMonth.value).subtract(1, "M").format(),
          mthString:
            action.payload === "forward"
              ? moment(state.selectedMonth.value)
                  .add(1, "M")
                  .format("MMMM, YYYY")
              : moment(state.selectedMonth.value)
                  .subtract(1, "M")
                  .format("MMMM, YYYY"),
        },
      };

    case CHANGE_WINDOW:
      var windowStart =
        moment(state.selectedMonth.value).startOf("week").format("DD") === "01"
          ? moment(state.selectedMonth.value)
              .startOf("month")
              .startOf("week")
              .subtract(6, "days")
              .format("YYYY-MM-DD")
          : moment(state.selectedMonth.value)
              .startOf("month")
              .startOf("week")
              .add(1, "days")
              .format("YYYY-MM-DD");
      var windowEnd =
        moment(state.selectedMonth.value).endOf("month").format("DDD") === "Sun"
          ? moment(state.selectedMonth.value)
              .endOf("month")
              .format("YYYY-MM-DD")
          : moment(state.selectedMonth.value)
              .endOf("month")
              .endOf("week")
              .add(1, "days")
              .format("YYYY-MM-DD");
      return {
        ...state,
        selectedMonth: {
          ...state.selectedMonth,
          windowStart: windowStart,
          windowEnd: windowEnd,
        },
      };
    case SET_MONTH:
      windowStart = moment(action.payload)
        .startOf("month")
        .format("YYYY-MM-DD");
      windowEnd = moment(action.payload)
        .endOf("month")
        .add(1, "days")
        .format("YYYY-MM-DD");
      return {
        ...state,
        selectedMonth: {
          value: action.payload,
          mthString: moment(action.payload).format("MMM, YYYY"),
          windowStart: windowStart,
          windowEnd: windowEnd,
        },
      };
    default:
      return state;
  }
};

export default reducerFunction;
