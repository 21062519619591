/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useReducer, useContext } from "react";

import NccdContext from "./nccdContext";
import nccdReducer from "./nccdReducer";
import appContext from "../../../../context/app/appContext";
import moment from "moment";

// import { serverUrl } from "../../../../authConfig";
import {
  CHANGE_STATE,
  SHIFT_MONTH,
  SET_MONTH,
  BUILD_NCCD,
  CLEANUP_NCCD,
  SETUP_EMAIL,
} from "./types";

// import { msalConfig } from "../../../../authConfig";

// import axios from "axios";

const getStudentSeniorYear = (yearLevel) => {
  const yearsUntilSenior = 12 - yearLevel;

  var finalYear = Number(moment().format("YYYY")) + yearsUntilSenior;

  return finalYear;
};

export const NccdState = (props) => {
  const initialState = {
    students: [],
    showStudentFinder: false,
    recordType: -1,
    specificDates: [],
    specificDatesNoWeekends: [],
    includeWeekends: false,
    dateRange: {
      from: null,
      to: null,
    },
    nccdActions: [],
    selectedActions: [],
    evidence: [],
    evidenceCategories: [],
    blankCategories: {},
    CreatedBy: {},
    CreatedAt: "",
    SchoolDaysOnly: true,
    schoolDays: [],
    selectedTerm: "",
    selectedYear: Number(moment().format("YYYY")),
    schoolYears: [],
    LinkedExam: {
      selectOption: null,
      examId: null,
      isLinked: false,
    },
    nccdEmail: {
      isEmail: false,
      actionDescription: "",
    },
  };

  const [state, dispatch] = useReducer(nccdReducer, initialState);

  // const sortByProperty = async (data, sortByColumn) => {
  //   var sorted = data.reduce((r, a, i) => {
  //     if (!i || r[r.length - 1][0][sortByColumn] !== a[sortByColumn]) {
  //       return r.concat([[a]]);
  //     }
  //     r[r.length - 1].push(a);
  //     return r;
  //   }, []);

  //   return sorted;
  // };

  const changeNccdState = (property, value) => {
    dispatch({
      type: CHANGE_STATE,
      payload: {
        property: property,
        value: value,
      },
    });
  };

  const shiftMonth = (direction) => {
    dispatch({
      type: SHIFT_MONTH,
      payload: direction,
    });
  };
  const cleanupNccd = () => {
    dispatch({
      type: CLEANUP_NCCD,
    });
  };

  const setMonth = (value) => {
    console.log("hitting set month", value);
    dispatch({
      type: SET_MONTH,
      payload: value,
    });
  };

  const buildNccd = (value) => {
    dispatch({
      type: BUILD_NCCD,
      payload: value,
    });
  };

  const app_context = useContext(appContext);
  const { dbGet, dbUser } = app_context;

  const setupEmail = async (
    students,
    selectedActions,
    selectedExam,
    emailBody,
    allActions
  ) => {
    console.log("IN EMAIL SETUP");
    console.log("SELECTED ACTIONS", selectedActions);
    console.log("ALL ACTIONS", allActions);
    const categoryRes = await dbGet({ type: "getEvidenceCategories" });
    var obj = {};
    for (const row of categoryRes) {
      obj[row.RefCode] = false;
    }

    const evidenceCategories = categoryRes.map((entry) => {
      return {
        label: entry.CategoryDescriptions,
        value: entry.EvidenceCategoryId,
        RefCode: entry.RefCode,
      };
    });

    const blankCategories = obj;

    students = students.map((student) => {
      const { StudentId, FirstName, Surname, YearLevel, House, MisId, Email } =
        student;
      return {
        label: `${FirstName} ${Surname} (${MisId}, Senior ${getStudentSeniorYear(
          YearLevel
        )})`,
        value: StudentId,
        student: {
          StudentId: StudentId,
          Surname: Surname,
          FirstName: FirstName,
          House: House,
          YearLevel: YearLevel,
          MisId: MisId,
          Email: Email,
          SeniorYear: getStudentSeniorYear(YearLevel),
        },
      };
    });

    var linkedExam;

    if (selectedExam) {
      linkedExam = {
        examId: selectedExam.ExamId,
        selectOption: {
          label: `${selectedExam.ExamName} (${
            selectedExam.SubjectName
          }) ${moment(selectedExam.ExamDate).format("ll")} (Created by: ${
            selectedExam.CreatedByName
          })`,
          value: selectedExam.ExamId,
          exam: {
            ExamId: selectedExam.ExamId,
            ExamName: selectedExam.ExamName,
            ExamDatesTimes: JSON.stringify(selectedExam.ExamDatesTimes),
            SubjectName: selectedExam.SubjectName,
            CreatedByName: selectedExam.CreatedByName,
          },
        },
      };
    } else {
      linkedExam = {
        selectOption: null,
        examId: null,
      };
    }

    const evidence = allActions.map((entry) => {
      if (entry.value !== selectedActions.value) {
        return {
          ...entry,
          action: {
            ...entry.action,
            Files:[],
            Frequency: {
              numTimes: 0,
              period: "givenPeriod",
              totalTimes: 0,
              recordDates: [
                {
                  day: null,
                  formatted:null,
                },
              ],
            },
          },
          evidenceArray: evidenceCategories.filter(
            (element) => entry.action[element.RefCode]
          ),
          isValid: false,
        };
      } else {
        return {
          ...entry,
          action: {
            ...entry.action,
            Files: [],
            Notes: selectedExam
              ? `<p>Exam: ${selectedExam.ExamName} (${selectedExam.SubjectName}) </p>` +
                emailBody
              : emailBody,
            Frequency: {
              numTimes: 1,
              period: "givenPeriod",
              totalTimes: 1,
              recordDates: [
                {
                  day: moment().format(),
                  formatted: moment().startOf("day").format(),
                },
              ],
            },
          },
          evidenceArray: evidenceCategories.filter(
            (element) => entry.action[element.RefCode]
          ),
          isValid: true,
        };
      }
    });

    dispatch({
      type: SETUP_EMAIL,
      payload: {
        evidenceCategories: evidenceCategories,
        blankCategories: blankCategories,
        LinkedExam: linkedExam,
        students: students,
        selectedActions: [selectedActions],
        evidence: evidence,
        CreatedBy: dbUser,
        EntryId: dbUser.StaffId + Date.now(),
        CreatedAt: moment().format(),
        SchoolDaysOnly: true,
        recordType: 0,
        dateRange: {
          from: null,
          to: null,
        },
        specificDates: [
          { day: moment().toISOString(), formatted: moment().format() },
        ],
        nccdEmail: {
          isEmail: true,
          actionDescription: selectedActions.label,
        },
        nccdActions: [],
      },
    });
  };

  // useEffect(() => {
  //   if (state.nccdDays.length > 0 && dbUser) {
  //     getLessonsInRange();
  //   }
  // }, [state.nccdDays,dbUser]);

  return (
    <NccdContext.Provider
      value={{
        students: state.students,
        showStudentFinder: state.showStudentFinder,
        recordType: state.recordType,
        specificDates: state.specificDates,
        dateRange: state.dateRange,
        specificDatesNoWeekends: state.specificDatesNoWeekends,
        includeWeekends: state.includeWeekends,
        nccdActions: state.nccdActions,
        selectedActions: state.selectedActions,
        evidence: state.evidence,
        evidenceCategories: state.evidenceCategories,
        blankCategories: state.blankCategories,
        CreatedBy: state.CreatedBy,
        EntryId: state.EntryId,
        CreatedAt: state.CreatedAt,
        SchoolDaysOnly: state.SchoolDaysOnly,
        schoolDays: state.schoolDays,
        schoolYears: state.schoolYears,
        selectedYear: state.selectedYear,
        selectedTerm: state.selectedTerm,
        LinkedExam: state.LinkedExam,
        nccdEmail: state.nccdEmail,
        setupEmail,
        changeNccdState,
        shiftMonth,
        setMonth,
        buildNccd,
        cleanupNccd,
      }}
    >
      {props.children}
    </NccdContext.Provider>
  );
};
