import React, { useReducer, useContext } from "react";

import AssessmentContext from "./assessmentContext";
import assessmentReducer from "./assessmentReducer";
import appContext from "../../../../../../../context/app/appContext";

import { CHANGE_STATE, SETUP_QCAA, CLEAR_ALL_AARA } from "./types";

export const AssessmentState = (props) => {
  const app_context = useContext(appContext);
  const { dbGet } = app_context;

  const initialState = {
    provisionsAltered: false,
    selectedTab: 0,
    studiedSubjects: [],
    standardOptions: [],
    qcaaOptions: [],
    adjustmentSelectOptions: [],
    subjectSelectOptions: [],
    possibleAdjustments: [],
    possibleQcaaAdjustments: [],
    oldPossibleAdjustments: [],
    studentProvisions: [],
    oldStudentProvisions: [],
    adjustmentsAndSubjects: [],
    openDropdown: null,
    adjustmentsToEdit: [],
    qcaaValid: false,
    provBeingEdited: 1,
    showModal: false,
    approvalTypes: [],
    allQcaaAdjustments: [],
    qcaaByAdjustment: [],
    oldQcaaByAdjustment: [],
    qcaaBySubject: [],
    oldQcaaBySubject: [],
    oldQcaaValidSubjects: [],
    qcaaValidSubjects: [],
    qcaaValidUpdated: [],
    validLoading: false,
    qcaaReset: false,
    qcaaUpdated: false,
    canReset: false,
    canSubmit: false,
    currentProvision: "",
    oldCurrentProvision: "",
    schoolSubjects: [],
  };

  const [state, dispatch] = useReducer(assessmentReducer, initialState);

  const getQcaaByAdjustment = async (studentId) => {
    const res = await dbGet({
      type: "getQcaaAdjustmentsForStudent",
      StudentId: studentId,
      sortBy: "AdjustmentName",
      secondSortBy: "SubjectName",
    });

    const qcaaByAdjustment = res.map((adjustment) => {
      return {
        label: adjustment[0].AdjustmentName,
        value: adjustment[0].AdjustmentTypeId,
        StudentId: adjustment[0].StudentId,
        subjects: adjustment.map((subject) => {
          return {
            SubjectCode: subject.SubjectCode,
            SubjectName: subject.SubjectName,
            value: subject.SubjectCode,
            label: subject.SubjectName,
            Notes: subject.Notes ? JSON.parse(subject.Notes) : [],
          };
        }),
      };
    });

    return qcaaByAdjustment;
  };

  const getQcaaBySubject = async (studentId) => {
    const res = await dbGet({
      type: "getQcaaAdjustmentsForStudent",
      StudentId: studentId,
      sortBy: "SubjectName",
      secondSortBy: "AdjustmentName",
    });

    const qcaaBySubject = res.map((adjustment) => {
      return {
        label: adjustment[0].SubjectName,
        value: adjustment[0].SubjectCode,
        StudentId: adjustment[0].StudentId,
        adjustments: adjustment.map((subject) => {
          return {
            AdjustmentTypeId: subject.AdjustmentTypeId,
            AdjustmentName: subject.AdjustmentName,
            value: subject.AdjustmentTypeId,
            label: subject.AdjustmentName,
            Notes: subject.Notes ? JSON.parse(subject.Notes) : [],
          };
        }),
      };
    });

    return qcaaBySubject;
  };

  const getStudiedSubjects = async (studentId) => {
    const studiedSubjects = await dbGet({
      type: "checkIfStudentHasComplex",
      StudentId: studentId,
    });

    return studiedSubjects;
  };

  const getPossibleAdjustments = async () => {
    const possibleAdjustments = await dbGet({
      type: "getAdjustmentTypes",
    });

    return possibleAdjustments;
  };

  const getAdjustmentSelectOptions = (
    possibleAdjustments,
    qcaaByAdjustment,
    studentId
  ) => {
    const adjustmentSelectOptions = possibleAdjustments.map((adjustment) => {
      const foundAdjustment = qcaaByAdjustment.filter(
        (entry) => entry.value === adjustment.AdjustmentId
      )[0];

      var subjects;

      if (foundAdjustment) {
        if (foundAdjustment.subjects) {
          subjects = foundAdjustment.subjects;
        }
      } else {
        subjects = [];
      }

      return {
        label: adjustment.AdjustmentName,
        value: adjustment.AdjustmentId,
        StudentId: studentId,
        subjects: subjects,
      };
    });

    return adjustmentSelectOptions;
  };

  const getSubjectSelectOptions = (
    studiedSubjects,
    qcaaBySubject,
    studentId
  ) => {
    const subjectSelectOptions = studiedSubjects.map((subject) => {
      const foundSubject = qcaaBySubject.filter(
        (entry) => entry.value === subject.SubjectCode
      )[0];

      var adjustments;

      if (foundSubject) {
        if (foundSubject.adjustments) {
          adjustments = foundSubject.adjustments;
        }
      } else {
        adjustments = [];
      }

      return {
        label: subject.SubjectName,
        value: subject.SubjectCode,
        StudentId: studentId,
        adjustments: adjustments,
      };
    });

    return subjectSelectOptions;
  };

  const setupQcaa = async (studentId) => {
    var qcaaByAdjustment = [];
    var qcaaBySubject = [];
    var studiedSubjects = [];
    var possibleAdjustments = [];

    var adjustmentSelectOptions = [];
    var subjectSelectOptions = [];

    //checking if studies any valid subjects-------

    studiedSubjects = await getStudiedSubjects(studentId);

    //getting list of possible adjustments

    possibleAdjustments = await getPossibleAdjustments();

    //----checking if any current AARA
    qcaaByAdjustment = await getQcaaByAdjustment(studentId);

    qcaaBySubject = await getQcaaBySubject(studentId);

    //---getting valid adjustment options then putting the subjects valid for that adjustment and the student has

    adjustmentSelectOptions = getAdjustmentSelectOptions(
      possibleAdjustments,
      qcaaByAdjustment,
      studentId
    );

    subjectSelectOptions = getSubjectSelectOptions(
      possibleAdjustments,
      qcaaBySubject,
      studentId
    );

    dispatch({
      type: SETUP_QCAA,
      payload: {
        qcaaByAdjustment: qcaaByAdjustment,
        qcaaBySubject: qcaaBySubject,
        studiedSubjects: studiedSubjects,
        possibleAdjustments: possibleAdjustments,
        adjustmentSelectOptions: adjustmentSelectOptions,
        subjectSelectOptions: subjectSelectOptions,
      },
    });
  };

  const clearAllAara = () => {
    dispatch({ type: CLEAR_ALL_AARA });
  };

  const changeAssessmentState = (property, value) => {
    dispatch({
      type: CHANGE_STATE,
      payload: {
        property: property,
        value: value,
      },
    });
  };

  return (
    <AssessmentContext.Provider
      value={{
        selectedTab: state.selectedTab,
        studiedSubjects: state.studiedSubjects,
        possibleAdjustments: state.possibleAdjustments,
        possibleQcaaAdjustments: state.possibleQcaaAdjustments,
        studentProvisions: state.studentProvisions,
        adjustmentsAndSubjects: state.adjustmentsAndSubjects,
        openDropdown: state.openDropdown,
        adjustmentsToEdit: state.adjustmentsToEdit,
        qcaaValid: state.qcaaValid,
        provBeingEdited: state.provBeingEdited,
        showModal: state.showModal,
        approvalTypes: state.approvalTypes,
        allQcaaAdjustments: state.allQcaaAdjustments,
        qcaaBySubject: state.qcaaBySubject,
        qcaaByAdjustment: state.qcaaByAdjustment,
        oldQcaaByAdjustment: state.oldQcaaByAdjustment,
        oldQcaaBySubject: state.oldQcaaBySubject,
        qcaaUpdated: state.qcaaUpdated,
        standardOptions: state.standardOptions,
        oldPossibleAdjustments: state.oldPossibleAdjustments,
        oldStudentProvisions: state.oldStudentProvisions,
        canReset: state.canReset,
        canSubmit: state.canSubmit,
        adjustmentSelectOptions: state.adjustmentSelectOptions,
        subjectSelectOptions: state.subjectSelectOptions,
        qcaaOptions: state.qcaaOptions,
        qcaaReset: state.qcaaReset,
        provisionsAltered: state.provisionsAltered,
        qcaaValidSubjects: state.qcaaValidSubjects,
        oldQcaaValidSubjects: state.oldQcaaValidSubjects,
        qcaaValidUpdated: state.qcaaValidUpdated,
        validLoading: state.validLoading,
        currentProvision: state.currentProvision,
        schoolSubjects: state.schoolSubjects,
        oldCurrentProvision: state.oldCurrentProvision,
        clearAllAara,
        changeAssessmentState,
        setupQcaa,
        getQcaaByAdjustment,
        getQcaaBySubject,
        getStudiedSubjects,
        getPossibleAdjustments,
        getAdjustmentSelectOptions,
        getSubjectSelectOptions,
      }}
    >
      {props.children}
    </AssessmentContext.Provider>
  );
};
