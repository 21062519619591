/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
// import { get } from "../../api/get";
import AppContext from "../../context/app/appContext";
import { AiOutlineUser } from "react-icons/ai";
import styled from "styled-components";
import { SquareLoader } from "react-spinners";


const PhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const CustomImage = styled.img`
  height: 100px;
  width: auto;
`;

const PhotoBox = (props) => {
  const [photoData, setPhotoData] = useState("");

  const { id, height,container } = props;


  const appContext = useContext(AppContext);
  const { getPhoto } = appContext;

  useEffect(() => {
    const runEffect = async () => {
      try {
        const res = await getPhoto(container, id);
        setPhotoData(res);
      } catch (err) {
        setPhotoData(false)
      }
      
    };
    runEffect();
    return ()=>{
      setPhotoData("")
    }
  }, [id]);

  if (photoData === "") {
    return <SquareLoader size={20} color="#96643c" />;
  } else if (photoData === false) {
    return (
      <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
        <AiOutlineUser size={height} />
      </div>
    );
  } else {
    return (
      <PhotoContainer>
        <CustomImage
          style={{ height: `${height}px` }}
          alt=""
          src={`data:image/jpeg;base64,${photoData}`}
        />
      </PhotoContainer>
    );
  }
};

export default PhotoBox;
