import React from "react";
import Expandable from "../Expandable";
import { IoSettings } from "react-icons/io5";
import {
  AiOutlineControl,
  // AiOutlineHistory,
  AiOutlineSearch,
} from "react-icons/ai";
import { GiBodySwapping } from "react-icons/gi";
import { FaRegFilePdf } from "react-icons/fa";

const AdminMenu = (props) => {
  return (
    <Expandable
      {...props}
      icon={<AiOutlineControl />}
      title="Admin"
      subArray={[
        {
          title: "Adjustment Search",
          path: "/adjustmentSearch",
          accessLevel: 1,
          icon: <AiOutlineSearch />,
          padLeft: "20px",
        },

        {
          title: "Key Contact",
          path: "/cm",
          accessLevel: 1,
          icon: <GiBodySwapping />,
          padLeft: "20px",
        },

        {
          title: "Profile Export",
          icon: <FaRegFilePdf />,
          path: "/profileExport",
          accessLevel: 1,
          padLeft: "20px",
        },
        {
          title: "Settings",
          icon: <IoSettings />,
          path: "/admin",
          accessLevel: 1,
          padLeft: "20px",
        },
      ]}
    />
  );
};

export default AdminMenu;
